<template>
  <div class="login-section-form-data" v-if="$locale">
    <div class="login-section-title">{{ $locale["lost-pass-btn"] }}</div>
    <div class="login-section-data">
      <form id="login-data-form" class="code-confirm-form" v-on:submit.prevent>
        <div class="block-input">
          <label>{{ $locale["auth-email-code-label"] }}</label>
          <input
            autocomplete="off"
            name="code"
            :placeholder="$locale['auth-email-code-placeholder']"
            required
            @blur="$validate.validateInput($event, { tooltip: false })"
          />
        </div>
        <div class="block-input">
          <input type="nosubmit" :value="$locale['next-btn']" readonly @click="sendData('login-data-form')" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    sendData: function(formID) {
      const isValidForm = this.$validate.validateForm(`#${formID}`, { tooltip: false });
      const code = document.querySelector(".code-confirm-form input[name='code']").value;
      if (isValidForm) {
        this.$router.push(`/auth/pass?code=${code}`);
      }
    },
  },
};
</script>
